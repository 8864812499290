<template>
  <v-autocomplete
    v-model="selectedGraphs"
    :items="graphs"
    data-cy="troubleshooter-graph-selection-combobox"
    label="Add filter"
    placeholder="Type to search for a filter"
    multiple
    hide-selected
    item-title="name"
    item-value="value"
    return-object
    variant="solo-filled"
  >
    <template #chip="{ item, index }">
      <v-chip v-if="index < 4" size="small" color="primary">{{ item.title }}</v-chip>
      <span v-if="index === 4" class="text-grey text-caption align-self-center">
        (+{{ selectedGraphs.length - 4 }} other{{ selectedGraphs.length > 5 ? 's' : '' }})
      </span>
    </template>

    <template #item="{ props, item }">
      <div class="d-flex align-center">
        <v-col cols="10">
          <v-list-item v-if="canSelect(item?.raw).allow" v-bind="props" :title="item?.raw?.name" />
          <v-list-item
            v-else
            v-bind="props"
            :title="item?.raw?.name"
            :disabled="true"
            :subtitle="canSelect(item?.raw).reason"
          />
        </v-col>
        <v-col cols="2">
          <v-tooltip location="bottom">
            <template #activator="{ props: activatorProps }">
              <v-icon v-bind="activatorProps" size="small" class="mr-2" icon="mdi-information-outline" @click.stop />
            </template>
            <div class="tooltip">
              <p>
                <strong>Type:</strong>
                {{ item?.raw?.graphType }}
              </p>
              <p>
                <strong>Description:</strong>
                {{ item?.raw?.description }}
              </p>
              <p>
                <strong>Usage:</strong>
                {{ item?.raw?.usageInfo }}
              </p>
            </div>
          </v-tooltip>
        </v-col>
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { TroubleshooterStore } from '#stores'

  import { Graph, SelectionGrant } from '#types/troubleshooter'

  @Component({})
  class GraphSelectionComboBox extends Vue {
    private troubleshooterStore = new TroubleshooterStore()

    private oldSelection: Graph[] = []

    public get graphs() {
      return this.troubleshooterStore.allGraphs
    }

    public get selectedGraphs() {
      return this.troubleshooterStore.selectedGraphs
    }

    public canSelect(graph: Graph): SelectionGrant {
      return this.troubleshooterStore.canSelect([graph])
    }

    public set selectedGraphs(newSelection: Graph[]) {
      this.oldSelection.forEach((item) => {
        if (!newSelection.includes(item)) {
          this.troubleshooterStore.deSelectGraphs([item])
        }
      })
      this.troubleshooterStore.selectGraphs(newSelection)
      this.oldSelection = newSelection
    }
  }
  export default toNative(GraphSelectionComboBox)
</script>

<style scoped lang="scss">
  .tooltip {
    font-size: small;
    max-width: 30rem;
  }
</style>
