import { factoryResetStatuses } from '#views/members/constants'

import { deviceSetInfoFromArray } from '#utils/warranty/setInfo'

import {
  MarkAsReturnedData,
  MarkReturnedRequest,
  Nullable,
  ReturnAuthorizationInfo,
  ReturnDialogData,
  ReturnDialogUserChoices,
  ReturnItem,
  ReturnLabelData,
  SerialDetails,
  WarrantyData,
} from '#types'

/**
 * Data required to make decision if "mark as returned" can be performed.
 *
 * We should retrieve this data directly OUTO API instead of parsing it on Darwin side.
 */
export function extractMarkAsReturnedDataFromWarrantyData(warrantyData: WarrantyData | null): MarkAsReturnedData {
  let ringReturned = false
  let chargerReturned = false
  let cableReturned = false
  const ringSerial = warrantyData?.ringset.ringSerialNumber ?? null
  const chargerSerial = warrantyData?.ringset.chargerSerialNumber ?? null

  if (ringSerial) {
    const ringReturnedData = (warrantyData?.ringReturn?.returnAuthorization?.returnedItems ?? []).find(
      (r) => r.serialNumber === ringSerial,
    )
    ringReturned = !!ringReturnedData
  }

  if (chargerSerial) {
    const chargerReturnedData = (warrantyData?.chargerReturn?.returnAuthorization?.returnedItems ?? []).find(
      (r) => r.serialNumber === chargerSerial,
    )
    chargerReturned = !!chargerReturnedData
  }

  const cableReturnedData = (warrantyData?.chargerReturn?.returnAuthorization?.returnedItems ?? []).find(
    (r) => r.itemType === 'cable',
  )
  cableReturned = !!cableReturnedData

  return {
    ring: {
      serial: ringSerial,
      returnAuthorizationId: warrantyData?.ringReturn.returnAuthorization?.transactionId ?? null,
      returnStatus: warrantyData?.ringReturn?.returnAuthorization?.status ?? null,
      returned: ringReturned,
    },
    charger: {
      serial: chargerSerial,
      returnAuthorizationId: warrantyData?.chargerReturn.returnAuthorization?.transactionId ?? null,
      returnStatus: warrantyData?.chargerReturn?.returnAuthorization?.status ?? null,
      returned: chargerReturned,
    },
    cable: {
      returned: cableReturned,
    },
  }
}

export function extractReturnDialogDataFromMarkAsReturnedData(
  markAsReturnedData: MarkAsReturnedData,
): ReturnDialogData {
  let allowRingReturn = false
  let allowChargerReturn = false
  let allowCableReturn = false
  let ringTooltip = ''
  let chargerTooltip = ''
  const ringSerial = markAsReturnedData.ring.serial
  const chargerSerial = markAsReturnedData.charger.serial
  const ringReturnAuthorizationId = markAsReturnedData.ring.returnAuthorizationId
  const chargerReturnAuthorizationId = markAsReturnedData.charger.returnAuthorizationId

  if (ringSerial && ringReturnAuthorizationId && !markAsReturnedData.ring.returned) {
    allowRingReturn = true
  }

  if (chargerSerial && chargerReturnAuthorizationId) {
    if (!markAsReturnedData.charger.returned) {
      allowChargerReturn = true
    }
    if (!markAsReturnedData.cable.returned) {
      allowCableReturn = true
    }
  }

  if (ringSerial) {
    ringTooltip += `Ring serial: ${ringSerial}`
    if (ringReturnAuthorizationId) {
      ringTooltip += ` | RA ID: ${ringReturnAuthorizationId}`
    }
  }

  if (chargerSerial) {
    chargerTooltip += `Charger serial: ${chargerSerial}`
    if (chargerReturnAuthorizationId) {
      chargerTooltip += ` | RA ID: ${chargerReturnAuthorizationId}`
    }
  }

  const allowedReturnStatuses: string[] = [
    'Pending Refund',
    'Pending Receipt',
    'Pending Approval',
    'Partially Received',
    'Pending Refund/Partially Received',
  ]

  if (!markAsReturnedData.ring.returnStatus || !allowedReturnStatuses.includes(markAsReturnedData.ring.returnStatus)) {
    allowRingReturn = false
  }

  if (
    !markAsReturnedData.charger.returnStatus ||
    !allowedReturnStatuses.includes(markAsReturnedData.charger.returnStatus)
  ) {
    allowChargerReturn = false
    if (!markAsReturnedData.charger.returned) {
      /*
      Here we disallow cable returning when charger
      - Is not yet returned
      - Is not in allowed state to return
      => cable should not be allowed to return either
       */
      allowCableReturn = false
    }
  }

  return {
    ring: {
      allowReturn: allowRingReturn,
      serial: ringSerial,
      returnAuthorizationId: ringReturnAuthorizationId,
      tooltipText: ringTooltip.length > 0 ? ringTooltip : null,
    },
    charger: {
      allowReturn: allowChargerReturn,
      serial: chargerSerial,
      returnAuthorizationId: chargerReturnAuthorizationId,
      tooltipText: chargerTooltip.length > 0 ? chargerTooltip : null,
    },
    cable: {
      allowReturn: allowCableReturn,
    },
  }
}

class MarkReturnedError extends Error {}

export function extractMarkReturnedRequests(
  returnDialogUserChoices: ReturnDialogUserChoices,
  returnDialogData: ReturnDialogData,
  handlerEmail: string,
): MarkReturnedRequest[] {
  const requestsByAuthorizationId: { [key: string]: MarkReturnedRequest } = {}
  const requests: MarkReturnedRequest[] = []
  // TODO:
  // We should validate handlerEmail
  if (returnDialogUserChoices.returnRing) {
    if (!returnDialogData.ring.allowReturn) {
      throw new MarkReturnedError('Ring is not allowed to be returned')
    }
    if (!returnDialogData.ring.serial) {
      throw new MarkReturnedError('Ring serial number is missing')
    }
    if (!returnDialogData.ring.returnAuthorizationId) {
      throw new MarkReturnedError('Ring return authorization ID is missing')
    }
    // We only pass reason when ring was not factory reset (status = none). In other cases it should be empty string.
    const reason = returnDialogUserChoices.resetStatus == 'none' ? returnDialogUserChoices.reasonForNoReset : ''
    requestsByAuthorizationId[returnDialogData.ring.returnAuthorizationId] = {
      transactionId: returnDialogData.ring.returnAuthorizationId,
      components: [{ type: 'ring', serialNumber: returnDialogData.ring.serial.split(',')[0] }],
      handlerEmail: handlerEmail,
      factoryReset: {
        status: returnDialogUserChoices.resetStatus,
        reason: reason,
      },
    }
  }

  if (returnDialogUserChoices.returnCharger) {
    if (!returnDialogData.charger.allowReturn) {
      throw new MarkReturnedError('Charger is not allowed to be returned')
    }
    if (!returnDialogData.charger.serial) {
      throw new MarkReturnedError('Charger serial number is missing')
    }
    if (!returnDialogData.charger.returnAuthorizationId) {
      throw new MarkReturnedError('Charger return authorization ID is missing')
    }
    if (!returnDialogUserChoices.confirmChargerSerial) {
      throw new MarkReturnedError('Charger serial number (confirmation) is missing')
    }
    if (returnDialogUserChoices.confirmChargerSerial !== returnDialogData.charger.serial) {
      throw new MarkReturnedError('Charger serial numbers (actual and confirmation) do not match')
    }

    if (
      requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId] &&
      requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId].components.length > 0
    ) {
      requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId].components.push({
        type: 'charger',
        serialNumber: returnDialogData.charger.serial.split(',')[0],
      })
    } else {
      requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId] = {
        transactionId: returnDialogData.charger.returnAuthorizationId,
        components: [{ type: 'charger', serialNumber: returnDialogData.charger.serial.split(',')[0] }],
        handlerEmail: handlerEmail,
      }
    }
  }

  if (returnDialogUserChoices.returnCable) {
    if (!returnDialogData.cable.allowReturn) {
      throw new MarkReturnedError('Cable is not allowed to be returned')
    }

    if (returnDialogData.charger.returnAuthorizationId) {
      if (
        requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId] &&
        requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId].components.length > 0
      ) {
        requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId].components.push({
          type: 'cable',
        })
      } else {
        requestsByAuthorizationId[returnDialogData.charger.returnAuthorizationId] = {
          transactionId: returnDialogData.charger.returnAuthorizationId,
          components: [{ type: 'cable' }],
          handlerEmail: handlerEmail,
        }
      }
    }
  }

  Object.entries(requestsByAuthorizationId).forEach(([_key, request]) => {
    requests.push(request)
  })

  return requests
}

function deviceReturnedInfoBySerial(
  returnAuthorizationInfo: Nullable<ReturnAuthorizationInfo>,
  serial: Nullable<string>,
): ReturnItem | null {
  return (returnAuthorizationInfo?.returnedItems ?? []).find((r) => r.serialNumber === serial) ?? null
}

function extractSingleReturnLabel(
  deviceType: 'Ring' | 'Charger',
  deviceRA: ReturnAuthorizationInfo,
  deviceReturned: ReturnItem,
  deviceSerialNumber: string,
  deviceSerialInfo: SerialDetails,
  warrantyNotes: Nullable<string> = null,
): ReturnLabelData {
  if (!deviceRA.returnedItems || deviceRA.returnedItems.length === 0) {
    throw new Error('deviceRA.returnedItems cannot be missing or empty')
  }
  const returnNumber = deviceReturned.returnNumber.toString() ?? null
  const processingDate = deviceRA.returnedItems[0]?.createdAt ?? null
  const returnAuthorizationId = deviceRA.transactionId ?? null
  const warrantyReason = deviceRA.reason ?? null
  const manufacturingWeek = deviceSerialInfo?.manufacturingWeek ?? null
  const manufacturingYear = deviceSerialInfo?.manufacturingYear ?? null
  const factory = deviceSerialInfo?.factory ?? null
  const ringSize = deviceSerialInfo?.size ?? null
  const hwMajor = deviceSerialInfo?.hwMajor ?? null
  const setInformation = deviceSetInfoFromArray((deviceRA.returnedItems || []).map((r) => r.itemType))

  let factoryResetStatus: string | null = null
  if (
    deviceReturned?.factoryReset?.status &&
    Object.prototype.hasOwnProperty.call(factoryResetStatuses, deviceReturned.factoryReset.status)
  ) {
    factoryResetStatus = factoryResetStatuses[deviceReturned?.factoryReset?.status] ?? null
  }
  const factoryResetReason = deviceReturned?.factoryReset?.reason ?? ''
  return {
    returnNumber: returnNumber,
    returnProcessingDate: processingDate,
    returnAuthorizationId: returnAuthorizationId,
    warrantyReason: warrantyReason,
    deviceType: deviceType,
    serial: deviceSerialNumber,
    manufacturingWeek: manufacturingWeek,
    manufacturingYear: manufacturingYear,
    factory: factory,
    ringSize: ringSize,
    hwMajor: hwMajor,
    setInformation: setInformation,
    factoryResetStatus: factoryResetStatus,
    factoryResetReason: factoryResetReason?.length > 0 ? factoryResetReason : '-',
    warrantyNotes: warrantyNotes ?? '-',
    printButtonTitle: 'Print return label for ' + returnAuthorizationId + ' (' + setInformation + ')',
  }
}

/**
 * Extract return label data from warranty data
 */
export function extractReturnLabelDataFromWarrantyData(
  warrantyData: WarrantyData,
  warrantyNotes: Nullable<string> = null,
): ReturnLabelData[] {
  const returnLabels: ReturnLabelData[] = []
  const raIdsIdentical =
    warrantyData.chargerReturn?.returnAuthorization?.transactionId ===
    warrantyData.ringReturn.returnAuthorization?.transactionId

  if (
    warrantyData.ringReturn.returnAuthorization &&
    warrantyData.ringset.ringSerialNumber &&
    warrantyData.ringReturn.returnAuthorization.returnedItems
  ) {
    const ringSerial = warrantyData.ringset.ringSerialNumber
    const ringRA: Nullable<ReturnAuthorizationInfo> = warrantyData.ringReturn.returnAuthorization
    const ringReturned = deviceReturnedInfoBySerial(ringRA, ringSerial)
    const ringSerialInfo: Nullable<SerialDetails> = warrantyData.ringset.ringSerialInfo
    if (ringReturned && ringSerialInfo) {
      returnLabels.push(
        extractSingleReturnLabel('Ring', ringRA, ringReturned, ringSerial, ringSerialInfo, warrantyNotes),
      )
    }
  }

  /*
   * If return authorization IDs are identical we assume that also data on both return authorizations are identical and
   * only provide single return label (ring RA). It could be that some edge case in the wild (production environment)
   * breaks this assumption but then we just adapt after we receive a bug report. Like initially we assumed
   * that we only need one print label per ring and bug report (MXPRODS-4442) changed that.
   */
  if (
    !raIdsIdentical &&
    warrantyData.chargerReturn.returnAuthorization &&
    warrantyData.ringset.chargerSerialNumber &&
    warrantyData.chargerReturn.returnAuthorization.returnedItems
  ) {
    const chargerSerial = warrantyData.ringset.chargerSerialNumber
    const chargerRA: Nullable<ReturnAuthorizationInfo> = warrantyData.chargerReturn.returnAuthorization
    const chargerReturned = deviceReturnedInfoBySerial(chargerRA, chargerSerial)
    const chargerSerialInfo: Nullable<SerialDetails> = warrantyData.ringset.chargerSerialInfo
    if (chargerReturned && chargerSerialInfo) {
      returnLabels.push(
        extractSingleReturnLabel(
          'Charger',
          chargerRA,
          chargerReturned,
          chargerSerial,
          chargerSerialInfo,
          warrantyNotes,
        ),
      )
    }
  }

  return returnLabels
}
